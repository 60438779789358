<script setup lang="ts">
</script>

<template>
  <main class="default-layout">
    <article class="default-layout__wrapper">
      <slot slot="fixed" name="header"></slot>
      <slot name="default"></slot>
    </article>
  </main>
  <div class="flares-background">
    <div class="blue-flare-desktop"></div>
    <div class="purple-flare-desktop"></div>
    <div class="pink-flare"></div>
    <div class="purple-flare"></div>
    <div class="purple-flare card-flare"></div>
    <div class="blue-flare"></div>
  </div>

</template>

<style lang="sass" scoped>
@import "src/assets/sass/abstracts/variables"
@import "src/assets/sass/abstracts/mixins"

.default-layout
  display: flex
  justify-content: center
  padding-bottom: var(--screen-bottom-padding)
  width: 100%

  &__wrapper
    width: 100%
    max-width: 1440px

    &:not(:has(> header))
      padding-top: var(--safe-area-inset-top)


.page-container
  padding-bottom: 60px

.flares-background
  @include flex-center
  position: absolute
  z-index: -1
  background: $background
  bottom: 0
  width: 100%
  height: 100%

.blue-flare, .purple-flare, .pink-flare
  width: 140px
  height: 140px
  filter: blur(114px)
  position: fixed
  pointer-events: none

.pink-flare
  width: 200px
  height: 200px
  background: #B10DFF
  top: -100px
  right: -100px


.purple-flare
  background: #770DFF
  bottom: 0

  &.card-flare
    right: auto
    left: 20px
    top: 50px
    opacity: 0.4

.blue-flare
  background: #1574CB
  bottom: -100px
  right: 100px

@include medium-huge
  .blue-flare, .purple-flare, .pink-flare
    display: none

  .blue-flare-desktop, .purple-flare-desktop
    width: 100%
    height: 140px
    filter: blur(250px)
    border-radius: 100%
    position: fixed
    pointer-events: none

  .purple-flare-desktop
    background: #770DFF
    top: -100px
    left: 100px
    right: 100px

  .blue-flare-desktop
    background: #770DFF
    bottom: -100px
    left: 100px
    right: 100px
</style>
