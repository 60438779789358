<script setup lang="ts">

import Widget from "../../components/Widget.vue";
import { useRoute } from 'vue-router';
import { useStorage } from '@vueuse/core';
import { onBeforeMount } from 'vue';

const route = useRoute()
const safeAreaTop = useStorage('safeAreaTop', '0')
const safeAreaBottom = useStorage('safeAreaBottom', '0')
onBeforeMount(() => {
  const params = route.query
  if (params['safe-area-top']) safeAreaTop.value = params['safe-area-top']
  if (params['safe-area-bottom']) safeAreaBottom.value = params['safe-area-bottom']

  document.documentElement.style.setProperty('--safe-area-inset-top', safeAreaTop.value)
  document.documentElement.style.setProperty('--safe-area-inset-bottom', safeAreaBottom.value)
})
</script>

<template>

  <widget class="main-widget"/>
</template>

<style scoped lang="sass">
.main-widget
  position: relative
  z-index: 1
</style>
